import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Stack } from '@fluentui/react/lib/Stack';
import { DefaultPalette } from '@fluentui/react/lib/Styling';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
// import { Label } from '@fluentui/react/lib/Label';

import { initializeIcons } from '@fluentui/font-icons-mdl2';
//import * as ReactDOM from 'react-dom';
import { Icon } from '@fluentui/react/lib/Icon';
import { mergeStyles, mergeStyleSets } from '@fluentui/react/lib/Styling';


import { useDropzone } from 'react-dropzone';
import verifyPDF from '@ninja-labs/verify-pdf';
//import { getCertificatesInfoFromPDF } from '@ninja-labs/verify-pdf';

//import PagePdf from './PagePdf';

import moment from 'moment';
import { UAParser } from 'ua-parser-js';
import { nanoid } from 'nanoid'
import axios from 'axios';

//import { publicIp } from "public-ip";
var publicIp = require('public-ip');
//var geoip = require('geoip-lite');


const baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    //borderColor: '#eeeeee',
    borderColor: '#2F75BB',
    borderStyle: 'dashed',
    //backgroundColor: '#fafafa',
    backgroundColor: '#3789DC',
    color: '#bdbdbd',
    transition: 'border .3s ease-in-out',

};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

/// Fluent UI
// Styles definition
const stackStyles = {
    root: {
        background: '#fff',
    },
};
/* 
const filePathStyles = {
    root: {
        background: '#808080',
    },
};

const stackItemStyles = {
    root: {
        background: '#fff',
        color: '#808080',
        padding: 5,
    },
};
 */

// Tokens definition
const containerStackTokens = { childrenGap: 5 };

const itemStyles = {
    color: DefaultPalette.white,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
};

// Tokens definition
const stackTokens = { childrenGap: 5 };

/* Fluent UI Icons */
initializeIcons();

function Upload(props) {



    const iconClass = mergeStyles({
        /*  fontSize: 40,
         height: 40,
         width: 40, */
    });


    const classNames = mergeStyleSets({
        LightSeaGreenMain: [{ color: 'LightSeaGreen', margin: '0 65px', fontSize: 60, }, iconClass],
        LightSeaGreen: [{ color: 'LightSeaGreen', margin: '0 65px', fontSize: 40, }, iconClass],
        salmonMain: [{ color: 'salmon', margin: '0 65px', fontSize: 60, }, iconClass],
        salmon: [{ color: 'salmon', margin: '0 65px', fontSize: 40, }, iconClass],
        DodgerBlue: [{ color: 'DodgerBlue', margin: '0 25px', fontSize: 40, }, iconClass],
        redPDF: [{ color: '#ff0000', margin: '0 25px', fontSize: 40, }, iconClass],
        whiteMain: [{ color: 'white', margin: '0 25px', fontSize: 60, }, iconClass],
        white: [{ color: 'white', margin: '0 25px', fontSize: 40, }, iconClass],
        yellowWarning: [{ color: '#FFFF00', margin: '0 25px', fontSize: 40, }, iconClass],
        orangeWarning: [{ color: 'orange', margin: '0 65px', fontSize: 40, }, iconClass],
        trueColor: [{ color: 'LightSeaGreen', marginRight: 8, fontSize: 14, }, iconClass],
        trueColorMain: [{ color: 'LightSeaGreen', marginRight: 8, fontSize: 20, }, iconClass],
        falseColor: [{ color: 'salmon', marginRight: 8, fontSize: 14, }, iconClass],
        falseColorMain: [{ color: 'salmon', marginRight: 8, fontSize: 20, }, iconClass],
        warningColor: [{ color: 'orange', marginRight: 8, fontSize: 14, }, iconClass],
        Install: [{ color: 'white', fontSize: '20px', marginLeft: 4 }, iconClass],
        Label: [{ color: '#0078D4', margin: '0 25px', fontSize: 40, }, iconClass],
        /* themeLabel: [{ color: '#0078D4', fontSize: '30px', marginRight: 8 }, iconClass], */
        themeLabel: [{ color: '#0078D7', fontSize: '30px', marginRight: 8 }, iconClass],
        greenMain: [{ color: '#107C10', margin: '0 65px', fontSize: 60, }, iconClass],
        green: [{ color: '#107C10', margin: '0 65px', fontSize: 40, }, iconClass],
        redMain: [{ color: '#FF4343', margin: '0 65px', fontSize: 60, }, iconClass],
        red: [{ color: '#FF4343', margin: '0 65px', fontSize: 40, }, iconClass],
        greenCheckMain: [{ color: '#107C10', marginRight: 8, fontSize: 20, }, iconClass],
        greenCheck: [{ color: '#107C10', marginRight: 8, fontSize: 14, }, iconClass],
        redCrossMain: [{ color: '#FF4343', marginRight: 8, fontSize: 20, }, iconClass],
        redCross: [{ color: '#FF4343', marginRight: 8, fontSize: 14, }, iconClass],
        greenEtax: [{ color: '#107C10', marginRight: 0, fontSize: 35, marginTop: 23, marginLeft: 20 }, iconClass],
    });


    let history = useHistory();
    /* useState */
    const [isUpload, setIsUpload] = useState(false);
    // useState 0f issuedTo
    const [issuedTocommonName, setIssuedTocommonName] = useState('');
    //const [issuedToemailAddress, setIssuedToemailAddress] = useState();
    //const [issuedToorganizationalUnitName, setIssuedToorganizationalUnitName] = useState();
    //const [issuedTolocation, setIssuedTolocation] = useState();
    const [taxId, setTaxId] = useState('');
    const [notAfter, setNotAfter] = useState('');
    const [notBefore, setNotBefore] = useState('');

    // useState 0f issuedBy
    const [issuedBycommonName, setIssuedBycommonName] = useState('');
    const [issuedByorganizationName, setIssuedByorganizationName] = useState('');
    //const [issuedByemailAddress, setIssuedByemailAddress] = useState();
    //const [issuedBycountryName, setIssuedBycountryName] = useState();

    // Checking
    const [verified, setVerified] = useState(false);
    const [authenticity, setAuthenticity] = useState(false);
    const [integrity, setIntegrity] = useState(false);
    const [expired, setExpired] = useState(false);
    //const [clientCertificate, setClientCertificate] = useState(false);

    //validityPeriod
    //const [validityPeriod, setValidityPeriod] = useState('');

    const [file, setFile] = useState([]);

    // File details
    const [filePath, setFilePath] = useState();
    const [fileSize, setFileSize] = useState();
    const [documentLastModified, setDocumentLastModified] = useState();

    const [error, setError] = useState(false);

    //var ua = new UAParser().getResult();
    //console.log('UA: ', ua);
    const [ua] = useState(new UAParser().getResult());
    const [id, setId] = useState('');

    // const [checkEtaxRdComplete, setCheckEtaxRdComplete] = useState(false);
    const [isCheckingEtaxRd, setIsCheckingEtaxRd] = useState(false);
    const [eTaxName, setEtaxName] = useState('-');
    const [eTaxNumber, setEtaxNumber] = useState('-');
    const [eTaxTaxInvoice, setEtaxTaxInvoice] = useState(false);
    const [eTaxReceipt, setEtaxReceipt] = useState(false);
    const [eTaxStart, setEtaxStart] = useState('-');
    const [eTaxEnd, setEtaxEnd] = useState('-');

    function decode_utf8(s) {
        //return decodeURIComponent(escape(s));

        let fixedstring;

        try {
            // If the string is UTF-8, this will work and not throw an error.
            fixedstring = decodeURIComponent(escape(s));
        } catch (e) {
            // If it isn't, an error will be thrown, and we can assume that we have an ISO string.
            fixedstring = s;
        }

        return fixedstring
    }

    const toThaiDateString = (isoDateTime) => {
        let date = '';
        date = new Date(isoDateTime);
    
        let year = date.getFullYear() + 543;
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        let numOfDay = date.getDate().toString().padStart(2, '0');
    
        return `${numOfDay}/${month}/${year}`;
      };
    

    function checkEtaxRd(taxId) {

        setIsCheckingEtaxRd(true);

        axios
            .post(
                'https://etaxws.rd.go.th/etaxapi/services/checkRegstration',
                {
                    taxNo: taxId,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then(
                (response) => {
                    console.log(response);

                    // let _docs = [];
                    let _data = response.data;
                    let _registerDate = '';
                    let _endDate = '-';

                    if (_data.status && _data.data.taxNo) {
                        console.log('e-Tax data: ', _data);

                        _registerDate = toThaiDateString(new Date(_data.data.registerDatetime).toISOString());

                        if (_data.data.endDatetime) {
                            _endDate = toThaiDateString(new Date(_data.data.endDatetime).toISOString());
                        }
/* 
                        _docs.push({
                            key: _data.data.taxNo,
                            name: _data.data.taxName,
                            taxInvoice: _data.data.documentType.indexOf('ใบกำกับภาษี') !== -1 ? 'Yes' : '-',
                            receipt: _data.data.documentType.indexOf('รับ') !== -1 ? 'Yes' : '-',
                            registerDate: _registerDate,
                            endDate: _endDate,
                            remark: _data.data.remark || '-',
                        });
 
                        console.log('_docs: ', _docs);
*/

                        setEtaxName(_data.data.taxName);
                        setEtaxNumber(_data.data.taxNo);
                        setEtaxTaxInvoice(_data.data.documentType.indexOf('ใบกำกับภาษี') !== -1 ? true : false);
                        setEtaxReceipt(_data.data.documentType.indexOf('รับ') !== -1 ? true : false);
                        setEtaxStart(_registerDate);
                        setEtaxEnd(_endDate);


                    }
                    // setCheckEtaxRdComplete(true);
                    setIsCheckingEtaxRd(false);
                })
            .catch((error) => {
                console.log(error);
                // setCheckEtaxRdComplete(true);
                setIsCheckingEtaxRd(false);
            }
            );

    }



    /** Drop Zone */
    const onDrop = useCallback(async acceptedFiles => {
        /* 
                setFiles(acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })));
         */



        console.log('acceptedFiles: ', acceptedFiles);

        console.log('UA: ', ua);

        let checkId = nanoid();
        let code = nanoid();

        setId(checkId);

        let ipV4 = null;
        //let ipV6 = null;
        //let location = null;

        try {
            ipV4 = await publicIp.v4();
            //ipV6 = await publicIp.v6();
            /* await publicIp.v4().then(ip => {
                ipV4 = ip;
                //location = geoip.lookup(ip);
            });    */

        } catch (error) {
            console.log('Public IP: ', error);
        }

        console.log('IP V4: ', ipV4);
        //console.log('IP V6: ', ipV6);
        //console.log('Location: ', location);

        setFile(acceptedFiles[0]);


        let reader = new FileReader();
        reader.onload = function (e) {
            //console.log(reader.result)

            setIssuedTocommonName('');
            setTaxId('');
            setNotBefore('');
            setNotAfter('');

            setIssuedBycommonName('');
            setIssuedByorganizationName('');



            try {

                const { verified, authenticity,
                    integrity,
                    expired,
                    meta } = verifyPDF(reader.result);
                //const certs = getCertificatesInfoFromPDF(reader.result);

                console.log(reader)
                console.log("verified", verified)

                let _certificates = [];
                if (meta.certs.length > 0) {
                    _certificates = [
                        {
                            issuedBy: meta.certs[0].issuedBy,
                            issuedTo: meta.certs[0].issuedTo,
                            validityPeriod: meta.certs[0].validityPeriod
                        }
                    ];

                    _certificates[0].issuedTo.commonName = decode_utf8(_certificates[0].issuedTo.commonName);
                }

                let _platform = {
                    name: 'leceipt',
                    channel: 'web'
                };

                
                let formData = new FormData();
                formData.append('file', acceptedFiles[0]);
                formData.append('checkId', checkId);
                formData.append('code', code);
                formData.append('certificates', new Blob([JSON.stringify(_certificates)], { type: 'application/json' }));
                formData.append('ua', new Blob([JSON.stringify(ua)], { type: 'application/json' }));
                formData.append('ip', new Blob([JSON.stringify({ v4: ipV4, v6: null })], { type: 'application/json' }));
                formData.append('platform', new Blob([JSON.stringify(_platform)], { type: 'application/json' }));

                axios.post('https://ws.leceipt.com/external/check/etax', formData, {
                    headers: {
                        //'Authorization': 'Bearer ' + tokenResponse.accessToken,
                        //'Content-Type': 'application/json',
                        'Content-Type': 'multipart/form-data',
                        //'OID': 'b5cf38c9-5e52-4bd3-b737-caf5c6d30635'
                        'API-Key': 'c43d097fd98f4b05b003fce1e50668e0'
                    }
                })


                //document.getElementById("demo").innerHTML = certs[0].clientCertificate;
                console.log("authenticity", authenticity)
                console.log("integrity", integrity) //ความถูกต้องของลายเซ็น ไฟล์ไม่ถูกแก้
                console.log("expired", expired)
                console.log("meta: ", meta);

                //console.log(certs)

                setIsUpload(true)
                // issuedTo

                let _cn = meta.certs[0].issuedTo.commonName;

                console.log('CN: ', _cn);

                if (_cn) {
                    //if (!checkAscii(_cn)) {
                    _cn = decode_utf8(_cn);
                    //_cn = utf8.decode(_cn);
                    //}                 
                    setIssuedTocommonName(_cn);
                }



                //setIssuedToemailAddress(certs[0].issuedTo.emailAddress)
                //setIssuedToorganizationalUnitName(certs[0].issuedTo.organizationalUnitName)
                //const localityName = certs[0].issuedTo.localityName
                //const countryName = certs[0].issuedTo.countryName
                //setIssuedTolocation(localityName + ", " + countryName)

                // issuedBy
                setIssuedBycommonName(meta.certs[0].issuedBy.commonName);
                setIssuedByorganizationName(meta.certs[0].issuedBy.organizationName);
                //setIssuedByemailAddress(certs[0].issuedBy.emailAddress);
                //setIssuedBycountryName(certs[0].issuedBy.countryName);

                setVerified(verified);
                setAuthenticity(authenticity)
                setIntegrity(integrity)
                setExpired(expired)

                //setClientCertificate(meta.certs[0].clientCertificate)

                let _taxId = null;

                for (let i = 0; i < meta.message.certificates.length; i++) {
                    let _commonName = decode_utf8(meta.message.certificates[i].subject.attributes.find((el) => el.name === "commonName").value);

                    if (_commonName === _cn) {
                        _taxId = meta.message.certificates[i].subject.attributes.find((el) => el.type === "2.5.4.97");
                        console.log('Tax Id on Certificate: ', _taxId);
                        _taxId.value = String(_taxId.value).match(/\d+/)[0] || '';
                        console.log('Tax Id for checking: ', _taxId);
                        setTaxId(_taxId.value);
                        break;
                    }


                }

                /* 
                            if (_taxId) {
                                setTaxId(_taxId.value);
                            }
                 */
                setNotBefore(meta.certs[0].validityPeriod.notBefore);
                setNotAfter(meta.certs[0].validityPeriod.notAfter);

                setError(false);

                //check etax
                // checkEtaxRd('0105562022825');

                // 0994000158441 tax id กรมสรรพากร
                if (_taxId.value === '0994000158441') {

                } else {

                    checkEtaxRd(_taxId.value);

                }



            } catch (error) {
                console.log(error);
                setError(true);
                setIsUpload(false);
            }



            //const notBefore = meta.certs[0].validityPeriod.notBefore
            //const notAfter = meta.certs[0].validityPeriod.notAfter

            //setValidityPeriod(notBefore + " - " + notAfter)

            //file path
            console.log('file: ', acceptedFiles[0].path)
            if (acceptedFiles.length > 0) { setFilePath(acceptedFiles[0].path) }
            if (acceptedFiles.length > 0) { setFileSize(acceptedFiles[0].size) }
            if (acceptedFiles.length > 0) { setDocumentLastModified(new Date(acceptedFiles[0].lastModified)) }

        }
        reader.readAsArrayBuffer(acceptedFiles[0]);

        // eslint-disable-next-line
        
    }, []);



    const {

        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        onDrop,
        accept: 'application/pdf',
        multiple: false,
        minSize: 0,
        maxSize: 20971520,
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);



    /*    
       const thumbs = files.map(file => (
   
   
           <Stack tokens={stackTokens} className="fileDetails" key={file.path}>
   
   
               <Stack horizontal horizontalAlign="space-around">
   
                   <span><Icon aria-label="EntitlementPolicy" iconName="EntitlementPolicy" className={classNames.themeLabel} /></span>
                   <span>{file.path}</span>
                   <span>{(file.size / 1024).toFixed(2)} KB</span>
                   <span>{document.lastModified} น.</span>
               </Stack>
   
           </Stack>
   
       ));
   
    */


    // clean up
    /* 
    useEffect(() => () => {
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);



    useEffect(() => {

        console.log('userEffect Call!');

        checkEtaxRd('0105537148063');

    }, []);
 */
    return (

        <section className="container">
            <a href="https://www.leceipt.com" target="_blank" rel="noreferrer" ><img src="/images/leceipt-banner-01.gif" style={{marginTop: "-50px"}} /></a>
            <br />
            <br />
            <div style={{ backgroundColor: 'rgb(61, 153, 245)', height: '270px' }}>
                <div style={{ paddingTop: '1px' }}>
                    <div {...getRootProps({ style })} className="dropzone">
                        <input {...getInputProps()} />
                        <Icon aria-label="PDF" iconName="PDF" className={classNames.whiteMain} />
                        <DefaultButton style={{ fontSize: "20px", height: "50px", width: "150px", marginTop: '20px' }}>เลือกไฟล์</DefaultButton>
                        <br />
                        <p style={{ color: 'white' }}>
                            หรือ ลากไฟล์ PDF มาวางที่นี่
                        </p>
                    </div>

                </div>
            </div>
            <br />
            <br />

            {!error && !isUpload &&
                <Stack horizontal horizontalAlign="center" styles={stackStyles}>

                    <span style={{ marginTop: "15px" }}>หมายเหตุ: ระบบจะทำการตรวจสอบลายเซ็นดิจิทัล แต่ไม่ได้ตรวจสอบความถูกต้องของข้อความในเอกสาร</span>

                </Stack>
            }

            {error &&
                <Stack tokens={containerStackTokens}>
                    {/* 
                <Stack horizontal horizontalAlign="center" styles={stackStyles}>
                    
                    <h1 style={{ marginTop: "15px" }}>เอกสารไม่สมบูรณ์ หรือเอกสารไม่มีลายเซ็นดิจิทัล</h1>                

                </Stack>
 */}
                    <Stack horizontal horizontalAlign="center" styles={stackStyles}>

                        <h1 style={{ marginTop: "15px", color: "#1A1A1A" }}>รายงานการตรวจสอบเอกสาร e-Tax Invoice & e-Receipt</h1>

                    </Stack>
                    {/* <br/>  */}
                    <span style={{ textAlign: 'center', fontSize: '16px', marginTop: '12px', color: 'gray' }}>เลขที่ {id}</span>
                    {/* <Separator/>*/}
                    {/* <br/>  */}

                    <br />


                    <Stack horizontal horizontalAlign="center">
                        <span><Icon aria-label="DeactivateOrders" iconName="DeactivateOrders" className={classNames.redMain} /></span>
                    </Stack>
                    <br />
                    <Stack horizontal horizontalAlign="center">
                        <Icon aria-label="ErrorBadge" iconName="ErrorBadge" className={classNames.redCrossMain} /><span style={{ fontSize: '20px', marginTop: '-5px' }}>เอกสารไม่สมบูรณ์ หรือเอกสารไม่มีลายเซ็นดิจิทัล</span>
                    </Stack>

                    <br />
                    <br />

                    <span className="headtitle">รายละเอียดเอกสารที่ตรวจสอบ (Document)</span>
                    <Stack tokens={stackTokens} className="details">
                        {/* 
                    <Stack horizontal horizontalAlign="space-around" style={{ paddingTop: '10px' }}>

                        <span><Icon aria-label="EntitlementPolicy" iconName="EntitlementPolicy" className={classNames.themeLabel} /></span>
                        <span>{file.path}</span>
                        <span>{(file.size / 1024).toFixed(2)} KB</span>
                        {/* <span>{moment(documentLastModified).utcOffset(7).format("DD/MM/YYYY  HH:mm:ss")} น.</span> 
                        <span>{moment().utcOffset(7).format("DD/MM/YYYY  HH:mm:ss")} น.</span>
                        {/* <span>{document.lastModified} น.</span> 
                    </Stack>
                */}
                        <Stack horizontal horizontalAlign="start">
                            <Stack vertical style={{ width: "50px" }}>
                                <span className="resultTopics"><Icon aria-label="TextDocument" iconName="TextDocument" className={classNames.themeLabel} /></span>
                            </Stack>
                            <Stack vertical style={{ width: "370px" }}>
                                <span style={{ paddingTop: "24px" }} className="resultTopics">ชื่อไฟล์ (File Name)</span>
                            </Stack>
                            <Stack vertical style={{ width: "800px" }}>
                                <span className="resultDetails">{file.path}</span>
                            </Stack>
                        </Stack>

                        <Stack horizontal horizontalAlign="start">
                            <Stack vertical style={{ width: "50px" }}>
                                <span className="resultTopics"><Icon aria-label="HardDrive" iconName="HardDrive" className={classNames.themeLabel} /></span>
                            </Stack>
                            <Stack vertical style={{ width: "370px" }}>
                                <span style={{ paddingTop: "24px" }} className="resultTopics">ขนาดไฟล์ (File Size)</span>
                            </Stack>
                            <Stack vertical style={{ width: "800px" }}>
                                <span className="resultDetails">{(file.size / 1024).toFixed(2)} KB</span>
                            </Stack>
                        </Stack>

                        <Stack horizontal horizontalAlign="start">
                            <Stack vertical style={{ width: "50px" }}>
                                <span className="resultTopics"><Icon aria-label="ComplianceAudit" iconName="ComplianceAudit" className={classNames.themeLabel} /></span>
                            </Stack>
                            <Stack vertical style={{ width: "370px" }}>
                                <span style={{ paddingTop: "24px" }} className="resultTopics">เวลาตรวจสอบ (Check Time)</span>
                            </Stack>
                            <Stack vertical style={{ width: "800px" }}>
                                <span className="resultDetails">{moment().utcOffset(7).format("DD/MM/YYYY  HH:mm:ss")} น. (เวลาประเทศไทย)</span>
                            </Stack>
                        </Stack>




                    </Stack>

                </Stack>
            }


            {isUpload &&
            // {true &&

                <Stack tokens={containerStackTokens}>

                    <Stack horizontal horizontalAlign="center" styles={stackStyles}>

                        <h1 style={{ marginTop: "15px", color: "#1A1A1A" }}>รายงานการตรวจสอบเอกสาร e-Tax Invoice & e-Receipt</h1>

                    </Stack>
                    {/* <br/>  */}
                    <span style={{ textAlign: 'center', fontSize: '16px', marginTop: '12px', color: 'gray' }}>เลขที่ {id}</span>
                    {/* <Separator/>*/}
                    {/* <br/>  */}

                    <br />


                    <Stack horizontal horizontalAlign="center">


                        {/* {(isUpload && !verified) && <span><Icon aria-label="ReportWarning" iconName="ReportWarning" className={classNames.redMain} /></span>} */}
                        {/* {(isUpload && verified) && <span><Icon aria-label="WaitlistConfirm" iconName="WaitlistConfirm" className={classNames.LightSeaGreen} /></span>} */}
                        {/* {(isUpload && verified) && <span><Icon aria-label="ActivateOrders" iconName="ActivateOrders" className={classNames.greenMain} /></span>} */}

                        {!isCheckingEtaxRd 
                            ?
                                (verified && (eTaxReceipt || (taxId === '0994000158441')))
                                ?
                                    <span><Icon aria-label="ActivateOrders" iconName="ActivateOrders" className={classNames.greenMain} /></span>
                                :
                                    <span><Icon aria-label="ReportWarning" iconName="ReportWarning" className={classNames.redMain} /></span>
                            :
                                <Spinner size={SpinnerSize.large} style={{ paddingTop: "40px" }} label="กำลังตรวจสอบเอกสาร..." ariaLive="assertive" labelPosition="right" />
                        }
                    </Stack>
                    <br/>
                    {!isCheckingEtaxRd 
                        ?
                            (verified && (eTaxReceipt || (taxId === '0994000158441')))
                            ?
                                <Stack horizontal horizontalAlign="center">
                                    <Icon aria-label="SkypeCircleCheck" iconName="SkypeCircleCheck" className={classNames.greenCheckMain} /><span style={{ fontSize: '20px', marginTop: '-5px' }}>เอกสารน่าเชื่อถือ</span>
                                </Stack>
                            :
                                <Stack horizontal horizontalAlign="center">
                                    <Icon aria-label="ErrorBadge" iconName="ErrorBadge" className={classNames.redCrossMain} /><span style={{ fontSize: '20px', marginTop: '-5px' }}>เอกสารไม่น่าเชื่อถือ</span>
                                </Stack>
                        :
                            <br/>
                    }



                    {false && <Stack horizontal horizontalAlign="center" styles={stackStyles}>
                        <span style={itemStyles}>
                            <Icon aria-label="PDF" iconName="PDF" className={classNames.redPDF} />
                        </span>
                        <span style={itemStyles}>
                            <PrimaryButton text="PDF Download" onClick={() => {
                                history.push({
                                    pathname: '/pagepdf',
                                    state: {
                                        filePath: filePath,
                                        fileSize: fileSize,
                                        documentLastModified: documentLastModified,
                                        verified: verified,
                                        authenticity: authenticity,
                                        integrity: integrity,
                                        expired: expired,
                                        issuedBycommonName: issuedBycommonName,
                                        //issuedByorganizationName: issuedByorganizationName,
                                        //issuedByemailAddress: issuedByemailAddress,
                                        //issuedBycountryName: issuedBycountryName,
                                        issuedTocommonName: issuedTocommonName,
                                        //issuedToemailAddress: issuedToemailAddress,
                                        //issuedToorganizationalUnitName: issuedToorganizationalUnitName,
                                        //issuedTolocation: issuedTolocation,
                                    }
                                })
                            }} />



                            <Icon aria-label="Installation" iconName="Installation" className={classNames.Install} />
                        </span>
                    </Stack>}


                    {/* {isUpload && <Stack>{thumbs}</Stack>} */}
                    <br />
                    <br />

                    <span className="headtitle">ผลการตรวจสอบเอกสาร (Check Result)</span>
                    <Stack tokens={stackTokens} className="resultBorder">
                        <Stack horizontal horizontalAlign="space-around" styles={stackStyles}>
                            {/* 
                        <Stack vertical tokens={{ childrenGap: 15 }}>
                            {(isUpload && !verified) && <span><Icon aria-label="ShieldAlert" iconName="ShieldAlert" className={classNames.salmon} /></span>}
                            {(isUpload && verified) && <span><Icon aria-label="ShieldSolid" iconName="ShieldSolid" className={classNames.LightSeaGreen} /></span>}
                            {(isUpload && !verified) && <span className="labelTitle"><Icon aria-label="ErrorBadge" iconName="ErrorBadge" className={classNames.falseColor} />เอกสารไม่น่าเชื่อถือ</span>}
                            {(isUpload && verified) && <span className="labelTitle"><Icon aria-label="SkypeCircleCheck" iconName="SkypeCircleCheck" className={classNames.trueColor} />เอกสารน่าเชื่อถือ</span>}
                        
                        </Stack>
 */}
                            <Stack vertical tokens={{ childrenGap: 15 }}>
                                {(isUpload && !integrity) && <span><Icon aria-label="PenWorkspace" iconName="PenWorkspace" className={classNames.red} /></span>}
                                {(isUpload && integrity) && <span><Icon aria-label="PenWorkspace" iconName="PenWorkspace" className={classNames.green} /></span>}
                                {(isUpload && !integrity) && <span className="labelTitle"><Icon aria-label="ErrorBadge" iconName="ErrorBadge" className={classNames.redCross} />ลายเซ็นดิจิทัลไม่ถูกต้อง</span>}
                                {(isUpload && integrity) && <span className="labelTitle"><Icon aria-label="SkypeCircleCheck" iconName="SkypeCircleCheck" className={classNames.greenCheck} />ลายเซ็นดิจิทัลถูกต้อง</span>}

                            </Stack>

                            <Stack vertical tokens={{ childrenGap: 15 }}>
                                {(isUpload && !authenticity) && <span><Icon aria-label="DeactivateOrders" iconName="DeactivateOrders" className={classNames.red} /></span>}
                                {(isUpload && authenticity) && <span><Icon aria-label="Certificate" iconName="Certificate" className={classNames.green} /></span>}
                                {(isUpload && !authenticity) && <span className="labelTitle"><Icon aria-label="ErrorBadge" iconName="ErrorBadge" className={classNames.redCross} />ใบรับรองไม่น่าเชื่อถือ</span>}
                                {(isUpload && authenticity) && <span className="labelTitle"><Icon aria-label="SkypeCircleCheck" iconName="SkypeCircleCheck" className={classNames.greenCheck} />ใบรับรองน่าเชื่อถือ</span>}

                            </Stack>

                            <Stack vertical tokens={{ childrenGap: 15 }}>
                                {(isUpload && expired) && <span><Icon aria-label="Warning" iconName="Warning" className={classNames.orangeWarning} /></span>}
                                {(isUpload && !expired) && <span><Icon aria-label="ReminderTime" iconName="ReminderTime" className={classNames.green} /></span>}
                                {(isUpload && !expired) && <span className="labelTitle"><Icon aria-label="SkypeCircleCheck" iconName="SkypeCircleCheck" className={classNames.greenCheck} />ใบรับรองยังไม่หมดอายุ</span>}
                                {(isUpload && expired) && <span className="labelTitle"><Icon aria-label="Warning" iconName="Warning" className={classNames.warningColor} />ใบรับรองหมดอายุ</span>}

                            </Stack>

                            {!isCheckingEtaxRd
                                ?
                                    <Stack vertical tokens={{ childrenGap: 15 }}>
                                        {(isUpload && !eTaxReceipt && (taxId !== '0994000158441')) && <span><Icon aria-label="UserRemove" iconName="UserRemove" className={classNames.red} /></span>}
                                        {(isUpload && eTaxReceipt && (taxId !== '0994000158441')) && <span><Icon aria-label="UserFollowed" iconName="UserFollowed" className={classNames.green} /></span>}
                                        {(isUpload && (taxId === '0994000158441')) && <span><Icon aria-label="UserFollowed" iconName="UserFollowed" className={classNames.green} /></span>}
                                        {(isUpload && !eTaxReceipt && (taxId !== '0994000158441')) && <span className="labelTitle"><Icon aria-label="ErrorBadge" iconName="ErrorBadge" className={classNames.redCross} />ไม่ใช่ผู้ได้รับอนุมัติ<br/>e-Tax Invoice & e-Receipt</span>}
                                        {(isUpload && eTaxReceipt && (taxId !== '0994000158441')) && <span className="labelTitle"><Icon aria-label="SkypeCircleCheck" iconName="SkypeCircleCheck" className={classNames.greenCheck} />เป็นผู้ได้รับอนุมัติ<br/>e-Tax Invoice & e-Receipt</span>}
                                        {(isUpload && (taxId === '0994000158441')) && <span className="labelTitle"><Icon aria-label="SkypeCircleCheck" iconName="SkypeCircleCheck" className={classNames.greenCheck} />กรมสรรพากร</span>}

                                    </Stack>
                                :
                                    <Stack vertical tokens={{ childrenGap: 15 }}>
                                        <Spinner size={SpinnerSize.large} style={{ paddingTop: "25px" }} label="กำลังตรวจสอบ..." ariaLive="assertive" labelPosition="right" />
                                    </Stack>
                            }
                        </Stack>

                    </Stack>

                    <br />
                    <br />

                    <span className="headtitle">รายละเอียดเอกสารที่ตรวจสอบ (Document)</span>
                    <Stack tokens={stackTokens} className="details">
                        {/* 
                    <Stack horizontal horizontalAlign="space-around" style={{ paddingTop: '10px' }}>

                        <span><Icon aria-label="EntitlementPolicy" iconName="EntitlementPolicy" className={classNames.themeLabel} /></span>
                        <span>{file.path}</span>
                        <span>{(file.size / 1024).toFixed(2)} KB</span>
                        {/* <span>{moment(documentLastModified).utcOffset(7).format("DD/MM/YYYY  HH:mm:ss")} น.</span> 
                        <span>{moment().utcOffset(7).format("DD/MM/YYYY  HH:mm:ss")} น.</span>
                        {/* <span>{document.lastModified} น.</span> 
                    </Stack>
 */}
                        <Stack horizontal horizontalAlign="start">
                            <Stack vertical style={{ width: "50px" }}>
                                <span className="resultTopics"><Icon aria-label="TextDocument" iconName="TextDocument" className={classNames.themeLabel} /></span>
                            </Stack>
                            <Stack vertical style={{ width: "370px" }}>
                                <span style={{ paddingTop: "24px" }} className="resultTopics">ชื่อไฟล์ (File Name)</span>
                            </Stack>
                            <Stack vertical style={{ width: "800px" }}>
                                <span className="resultDetails">{file.path}</span>
                            </Stack>
                        </Stack>

                        <Stack horizontal horizontalAlign="start">
                            <Stack vertical style={{ width: "50px" }}>
                                <span className="resultTopics"><Icon aria-label="HardDrive" iconName="HardDrive" className={classNames.themeLabel} /></span>
                            </Stack>
                            <Stack vertical style={{ width: "370px" }}>
                                <span style={{ paddingTop: "24px" }} className="resultTopics">ขนาดไฟล์ (File Size)</span>
                            </Stack>
                            <Stack vertical style={{ width: "800px" }}>
                                <span className="resultDetails">{(file.size / 1024).toFixed(2)} KB</span>
                            </Stack>
                        </Stack>

                        <Stack horizontal horizontalAlign="start">
                            <Stack vertical style={{ width: "50px" }}>
                                <span className="resultTopics"><Icon aria-label="ComplianceAudit" iconName="ComplianceAudit" className={classNames.themeLabel} /></span>
                            </Stack>
                            <Stack vertical style={{ width: "370px" }}>
                                <span style={{ paddingTop: "24px" }} className="resultTopics">เวลาตรวจสอบ (Check Time)</span>
                            </Stack>
                            <Stack vertical style={{ width: "800px" }}>
                                <span className="resultDetails">{moment().utcOffset(7).format("DD/MM/YYYY  HH:mm:ss")} น. (เวลาประเทศไทย)</span>
                            </Stack>
                        </Stack>




                    </Stack>



                    <span className="headtitle">รายละเอียดใบรับรองอิเล็กทรอนิกส์ผู้เซ็น (Signer Certificate)</span>
                    <Stack tokens={stackTokens} className="details">
                        <Stack horizontal horizontalAlign="start">
                            <Stack vertical style={{ width: "50px" }}>
                                <span className="resultTopics"><Icon aria-label="EditContact" iconName="EditContact" className={classNames.themeLabel} /></span>
                            </Stack>
                            <Stack vertical style={{ width: "370px" }}>
                                <span style={{ paddingTop: "24px" }} className="resultTopics">ผู้เซ็น (Signer)</span>
                            </Stack>
                            <Stack vertical style={{ width: "800px" }}>
                                <span className="resultDetails">{issuedTocommonName}</span>
                            </Stack>
                        </Stack>

                        {taxId &&

                            <Stack horizontal horizontalAlign="start">
                                <Stack vertical style={{ width: "50px" }}>
                                    <span className="resultTopics"><Icon aria-label="CityNext2" iconName="CityNext2" className={classNames.themeLabel} /></span>
                                </Stack>
                                <Stack vertical style={{ width: "370px" }}>
                                    <span style={{ paddingTop: "24px" }} className="resultTopics">เลขประจำตัวผู้เสียภาษี (Tax ID)</span>
                                </Stack>
                                <Stack vertical style={{ width: "800px" }}>
                                    <span className="resultDetails">{taxId}</span>
                                </Stack>
                            </Stack>

                        }


                        <Stack horizontal horizontalAlign="start">
                            <Stack vertical style={{ width: "50px" }}>
                                <span className="resultTopics"><Icon aria-label="DateTime" iconName="DateTime" className={classNames.themeLabel} /></span>
                            </Stack>
                            <Stack vertical style={{ width: "370px" }}>
                                <span style={{ paddingTop: "24px" }} className="resultTopics">ใบรับรองฯ เริ่มใช้งาน (Start Date)</span>
                            </Stack>
                            <Stack vertical style={{ width: "800px" }}>
                                <span className="resultDetails">{moment(notBefore).utcOffset(7).format("DD/MM/YYYY  HH:mm:ss")} น. (เวลาประเทศไทย)</span>
                            </Stack>
                        </Stack>



                        <Stack horizontal horizontalAlign="start">
                            <Stack vertical style={{ width: "50px" }}>
                                <span className="resultTopics"><Icon aria-label="DateTime2" iconName="DateTime2" className={classNames.themeLabel} /></span>
                            </Stack>
                            <Stack vertical style={{ width: "370px" }}>
                                <span style={{ paddingTop: "24px" }} className="resultTopics">ใบรับรองฯ หมดอายุ (Expire Date)</span>
                            </Stack>
                            <Stack vertical style={{ width: "800px" }}>
                                <span className="resultDetails">{moment(notAfter).utcOffset(7).format("DD/MM/YYYY  HH:mm:ss")} น. (เวลาประเทศไทย)</span>
                            </Stack>
                        </Stack>
                    </Stack>


                    {/* <br/>
                <br/>    */}
                    <span className="headtitle">รายละเอียดผู้ออกใบรับรองอิเล็กทรอนิกส์ (Certificate Authority)</span>

                    <Stack tokens={stackTokens} className="details">
                        <Stack horizontal horizontalAlign="start">
                            <Stack vertical style={{ width: "50px" }}>
                                <span className="resultTopics"><Icon aria-label="Bank" iconName="Bank" className={classNames.themeLabel} /></span>
                            </Stack>
                            <Stack vertical style={{ width: "370px" }}>
                                <span style={{ paddingTop: "24px" }} className="resultTopics">ผู้ออกใบรับรอง (Certificate Authority)</span>
                            </Stack>
                            <Stack vertical style={{ width: "800px" }}>
                                <span className="resultDetails">{issuedBycommonName}</span>
                            </Stack>

                        </Stack>



                        <Stack horizontal horizontalAlign="start">
                            <Stack vertical style={{ width: "50px" }}>
                                <span className="resultTopics"><Icon aria-label="CityNext2" iconName="CityNext2" className={classNames.themeLabel} /></span>
                            </Stack>
                            <Stack vertical style={{ width: "370px" }}>
                                <span style={{ paddingTop: "25px" }} className="resultTopics">หน่วยงาน (Organization)</span>
                            </Stack>
                            <Stack vertical style={{ width: "800px" }}>
                                <span className="resultDetails">{issuedByorganizationName}</span>
                            </Stack>

                        </Stack>

                    </Stack>

                    <span className="headtitle">ข้อมูลระบบ e-Tax Invoice & e-Receipt กรมสรรพากร</span>

                    {isCheckingEtaxRd 
                    ?

                        <Stack tokens={stackTokens} className="details">
                           
                            <Stack horizontal horizontalAlign="start">
                                <Spinner size={SpinnerSize.large} style={{ paddingLeft: "25px" }} label="กำลังตรวจสอบการลงทะเบียนระบบ e-Tax Invoice & e-Receipt..." ariaLive="assertive" labelPosition="right" />
                            </Stack>
 {/*  
                            <Stack horizontal horizontalAlign="start">

                                <Stack vertical style={{ width: "50px" }}>
                                    <Spinner style={{ paddingTop: "24px" }} size={SpinnerSize.large} />
                                </Stack>

                                <Stack vertical style={{ width: "370px" }}>
                                <span style={{ paddingTop: "24px" }} className="resultTopics">กำลังตรวจสอบการลงทะเบียนระบบ e-Tax Invoice & e-Receipt ...</span>
                                </Stack>

                                <Stack vertical style={{ width: "800px" }}>
                                    {/* <span className="resultDetails">{eTaxName}</span> 
                                </Stack>

                            </Stack>
    */}                        
                        </Stack>

                    :
                        <Stack tokens={stackTokens} className="details">

                            <Stack horizontal horizontalAlign="start">

                                <span style={{ paddingTop: "25px", fontWeight: 500 }} className="resultTopics">ชื่อผู้ประกอบการที่ได้รับอนุมัติ</span>

                            </Stack>

                            <Stack horizontal horizontalAlign="start">
                                <Stack vertical style={{ width: "50px" }}>
                                    <span className="resultTopics"><Icon aria-label="CityNext2" iconName="CityNext2" className={classNames.themeLabel} /></span>
                                </Stack>
                                <Stack vertical style={{ width: "370px" }}>
                                    <span style={{ paddingTop: "24px" }} className="resultTopics">ชื่อผู้ประกอบการ</span>
                                </Stack>
                                <Stack vertical style={{ width: "800px" }}>
                                    <span className="resultDetails">{eTaxName}</span>
                                </Stack>

                            </Stack>

                            <Stack horizontal horizontalAlign="start">
                                <Stack vertical style={{ width: "50px" }}>
                                    <span className="resultTopics"><Icon aria-label="ContactCard" iconName="ContactCard" className={classNames.themeLabel} /></span>
                                </Stack>
                                <Stack vertical style={{ width: "370px" }}>
                                    <span style={{ paddingTop: "25px" }} className="resultTopics">เลขประจำตัวผู้เสียภาษี (Tax ID)</span>
                                </Stack>
                                <Stack vertical style={{ width: "800px" }}>
                                    <span className="resultDetails">{eTaxNumber}</span>
                                </Stack>

                            </Stack>

                            <Stack horizontal horizontalAlign="start">

                                <span style={{ paddingTop: "25px", fontWeight: 500 }} className="resultTopics">เอกสารที่ได้รับอนุมัติให้จัดทำ ส่งมอบ และเก็บรักษา</span>

                            </Stack>

                            <Stack horizontal horizontalAlign="start">
                                <Stack vertical style={{ width: "50px" }}>
                                    <span className="resultTopics"><Icon aria-label="M365InvoicingLogo" iconName="M365InvoicingLogo" className={classNames.themeLabel} /></span>
                                </Stack>
                                <Stack vertical style={{ width: "370px" }}>
                                    <span style={{ paddingTop: "25px" }} className="resultTopics">ใบกำกับภาษีอิเล็กทรอนิกส์</span>
                                </Stack>
                                <Stack vertical style={{ width: "800px" }}>
                                    {/* <span className="resultDetails">{eTaxTaxInvoice}</span> */}
                                    {eTaxTaxInvoice
                                        ?
                                        <Stack horizontal horizontalAlign="start">
                                            <Icon aria-label="SkypeCircleCheck" iconName="SkypeCheck" className={classNames.greenEtax} /><span style={{ fontSize: '50px', marginTop: '50px' }}></span>
                                        </Stack>
                                        :
                                        <Stack horizontal horizontalAlign="start">
                                            <span className="resultDetails">-</span>
                                        </Stack>

                                    }
                                </Stack>

                            </Stack>

                            <Stack horizontal horizontalAlign="start">
                                <Stack vertical style={{ width: "50px" }}>
                                    <span className="resultTopics"><Icon aria-label="M365InvoicingLogo" iconName="M365InvoicingLogo" className={classNames.themeLabel} /></span>
                                </Stack>
                                <Stack vertical style={{ width: "370px" }}>
                                    <span style={{ paddingTop: "25px" }} className="resultTopics">ใบเสร้จรับเงินอิเล็กทรอนิกส์</span>
                                </Stack>
                                <Stack vertical style={{ width: "800px" }}>
                                    {/* <span className="resultDetails">{eTaxReceipt}</span> */}
                                    {eTaxReceipt
                                        ?
                                        <Stack horizontal horizontalAlign="start">
                                            <Icon aria-label="SkypeCircleCheck" iconName="SkypeCheck" className={classNames.greenEtax} /><span style={{ fontSize: '50px', marginTop: '50px' }}></span>
                                        </Stack>
                                        :
                                        <Stack horizontal horizontalAlign="start">
                                            <span className="resultDetails">-</span>
                                        </Stack>

                                    }
                                </Stack>

                            </Stack>

                            <Stack horizontal horizontalAlign="start">

                                <span style={{ paddingTop: "25px", fontWeight: 500 }} className="resultTopics">วันที่ได้รับอนุมัติ</span>

                            </Stack>

                            <Stack horizontal horizontalAlign="start">

                                <Stack vertical style={{ width: "50px" }}>
                                    <span className="resultTopics"><Icon aria-label="DateTime" iconName="DateTime" className={classNames.themeLabel} /></span>
                                </Stack>
                                <Stack vertical style={{ width: "370px" }}>
                                    <span style={{ paddingTop: "25px" }} className="resultTopics">เริ่มต้น</span>
                                </Stack>
                                <Stack vertical style={{ width: "800px" }}>
                                    <span className="resultDetails">{eTaxStart}</span>
                                </Stack>

                            </Stack>

                            <Stack horizontal horizontalAlign="start">

                                <Stack vertical style={{ width: "50px" }}>
                                    <span className="resultTopics"><Icon aria-label="DateTime2" iconName="DateTime2" className={classNames.themeLabel} /></span>
                                </Stack>
                                <Stack vertical style={{ width: "370px" }}>
                                    <span style={{ paddingTop: "25px" }} className="resultTopics">สิ้นสุด</span>
                                </Stack>
                                <Stack vertical style={{ width: "800px" }}>
                                    <span className="resultDetails">{eTaxEnd}</span>
                                </Stack>

                            </Stack>

                        </Stack>
                    }

                </Stack>

            }
        </section >

    )
}


export default Upload



